.home-parent{
  
    background-color: black;
}
.home-details{
    background-color: #8ED081;
  text-align: center;
  color: white;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.home-skill-data{
    width: 80%;
    margin: 0 auto;
    background-color: #75ab6a;
    padding: 4%;
    border-radius: 20px;
    margin-bottom: 50px;
}