@font-face {
  font-family: 'HK Grotesk';
  src: url(./Assets/HKGrotesk-Regular.otf);
}
* {
  font-family: 'HK Grotesk'!important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: white;
}
#root{
  height: 100% ;
}

body ,html{

 background-color: black;
  height: 100%;
  width: 100%;
  /* background-image: url('./Assets/pexels-eberhardgross-707344.jpg'); */
}

::-webkit-scrollbar{
  display: none;
}

.home_skill-container {
  transition: box-shadow 0.3s linear, transform 0.3s ease; /* Smooth transition for both properties */
}

.home_skill-container:hover {
  box-shadow: 0px 0px 10px 5px rgba(82, 82, 82, 0.5); /* White shadow on hover */
  transform: scale(1.02); /* Slight scaling effect on hover */
  cursor: pointer; /* Pointer cursor on hover */
}
.home_skill-img {
  animation: imgpopup 1s linear 0s forwards;
    /* Ensure 'forwards' is used to maintain the final state of the animation */
}




@keyframes imgpopup {
  0% {
    transform: translateY(50px);
    
    opacity: 0;
  }
  50% {
      transform: translateY(25px);
      opacity: 0.5;
    }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.MuiOutlinedInput-root{
  background-color: burlywood;
}
.MuiTextField-root.textField_styles{}

@media screen and (max-width: 560px) {
  .MuiTextField-root.textField_styles{
    width: 70% !important;
  }
  .MuiButton-root.send_btn_styles{
    width: 70% !important;
  }
  .form-container{
    /* width: 90%;
    height: 100%; */
    /* paddin: 5%; */
  }
  .skill-conteiner-cards{
    height: 100% !important;
  }
  .exp-conainer{
 background-color:'black'
  }
  .formContainer{
  
    padding: 4% !important;
  }
  .header-menu{
    display: none !important;
  }
  .home_skill-img-card{
    width: 90% !important;
  }
  .MuiTypography-root.exp-org-title{
    font-size: 1.3rem !important;
    padding-top: 1.5rem;
  }
  .top_icon{
    display: none !important  ;
  }
 
  
}
@media screen and (max-width:700px) {
  .card-designation{
    margin-top: 12% !important;
  }
  .display-phone{
    display: flex !important;
}
.display-detect{
  display: none !important;
}
}
.MuiPaper-root{
  background-color: black !important;
}