header{
    height: 50px;
    background-color: #225fe3;
}

.header-text {
    text-align: center;
    background-color: #030202;
    color: #FFF;
  }

.heder-drawer{
    background-color: #2b2b2b;
    position: static !important;
    display: inline;
    box-shadow: none;
}
.display-detect{
    width: 100%;
    display: flex;
    margin: auto;
    justify-content: space-between !important ;
}
.menu-list-item{
    text-align: center;
    padding: 10px;
    opacity: 1;
    margin: 5px;
    color: white;
    animation: menu_item 0.5s ease-in forwards ;
    
}
@keyframes  menu_item {
    0%{opacity: 0;}
    100%{opacity: 1}
}
/* @keyframes  menu_item {
    0%{opacity: ;}
    50%{}
    100%{}
}

@keyframes  menu_item {
    0%{opacity: ;}
    50%{}
    100%{}
} */
.MuiSwitch-track{
    filter: blur(3px);
}