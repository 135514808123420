.exp-parent{
   
    background-color: black;
    overflow: auto;
}
.exp-conteiner-data{
    padding: 1%;
  background-color: #030202;
  color: white;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.exp-skill-data-layout{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    flex-wrap: wrap;
    text-align: center;
    border-radius: 20px;
    
}